<template>
    <div class="main">
		<div class="wrapper">
			<div class="logo-wrapper">
				<v-img
					:src="require('../assets/logo_clock.svg')"
					contain
					width="100%"
				/>
			</div>


			<div class="form-wrapper" v-if="!resetDisabled">
				<v-flex xs12 style="padding: 20px 30px 10px 30px;">
                    <strong> {{ pageHeader }} </strong>
                </v-flex>
                <v-flex xs12 style="padding: 0px 30px 10px 30px;">
                    {{ pageContent }}
                </v-flex>

				<div>
					<v-text-field
					v-model="password"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPassword ? 'text' : 'password'"
					label="New password"
					prepend-inner-icon="mdi-lock"
					@click:append="showPassword = !showPassword"
					@keyup.enter="validate"
					></v-text-field>

                    <v-text-field
					v-model="confirmPassword"
					:append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showConfirmPassword ? 'text' : 'password'"
					label="Confirm password"
					prepend-inner-icon="mdi-lock"
					@click:append="showConfirmPassword = !showConfirmPassword"
					@keyup.enter="validate"
					></v-text-field>

					<div v-if="!valid" class="warning-wrapper">
						<div class="error-message">
							<p class="error--text">{{errorMessage}}</p>
						</div>
						<div>
							<v-icon color="error">mdi-alert-circle</v-icon>
						</div>
					</div>

					<v-btn 
					:loading="loading" 
					type="submit" 
					class="primary reset-btn" 
					@click="validate"
					>
					Save password
					</v-btn><br>
				</div>
			</div>

			<div v-else style="width: 100%;">
				<div style="width:100%; text-align: center; margin: 10px auto; color: red; font-size: 20px;">{{ globalErrorMessage }}</div>
			</div>

			<router-link to="/" class="message--text">Login page</router-link>

			<!-- <div class="logo-wrapper">
				<v-img
					:src="require('../assets/generic_logo.png')"
					contain
					width="100%"
				/>
			</div> -->
		</div>

		<v-snackbar
			v-model="passwordUpdateNotifier"
			fixed
			class="snackbar-offline-notifier"
			:color="snackbarColor"
		>
			<v-flex xs12 class="align-self-center" style="font-size: 16px; text-align: center;">
				<div>{{ passwordUpdateNotification }}</div>
			</v-flex>
		</v-snackbar>
	</div>
</template>

<script>
//import axios from 'axios'
const axios = require('axios');
import appConfig from '@/app_config'
// import jwt_decode from 'jwt-decode';

export default {

	name: 'NewPassword',

	data: () => ({
		valid: true,
		loading: false,
		password: "",
        confirmPassword: "",
        showPassword: false,
        showConfirmPassword: false,
		resetDisabled: false,
		passwordUpdateNotification: "",
		passwordUpdateNotifier: false,
		snackbarColor: "",
		globalErrorMessage: "",
		pageHeader: "",
		pageContent: ""
	}),
    props: {
		token: String
    },
	computed: {
        lview: {
            get: function(){
                return this.$t('views.settingPassword');
            }
        },
    },
	mounted(){
		this.$emit("set-state", "default");
		this.pageHeader=this.lview.headerPrefix
		this.pageContent= this.lview.pageContent
		this.isTokenValid(this.$route.params.token).then(() => {
			this.resetDisabled=false   
        }).catch(error => {
			console.log(error.response.data)
			this.resetDisabled=true
            if (typeof(error.response) === 'undefined'){
				this.globalErrorMessage = "Application failed to connect to the server. Please check your internet connection.";
				return;
			}

			switch(error.response.status){
				case 400:
					if(error.response.data.token[0].code=="INVALID_TOKEN"){
						this.globalErrorMessage=this.lview.invalidToken
					}
					else if(error.response.data.token[0].code=="EXPIRED_SIGNATURE"){
						this.globalErrorMessage=this.lview.linkOutdated
					}
					else{
						this.globalErrorMessage="Request rejected"
					}
					break
				case 401: 
					this.globalErrorMessage = "?";
					break
				case 429:
					this.globalErrorMessage = "Too many requests.";
					break;
				case 500:
					this.globalErrorMessage = "An internal server error has occured. Please report this message to the administrator.";
					break;
			}
        });
	},
    methods: {
		validate(){
			if(this.password==''){
				this.valid=false
				this.errorMessage='Required password'
				this.$forceUpdate();
			}
			else if(this.password.length<8){
				this.valid=false
				this.errorMessage='Password too short'
				this.$forceUpdate();
			}
			else if(!this.validatePassword(this.password)){
				this.valid=false
				this.errorMessage='Incorrect password structure'
				this.$forceUpdate();
			}
            else if(this.confirmPassword==''){
                this.valid=false
				this.errorMessage='Confirm password'
				this.$forceUpdate();
            }
			else if (this.password!==this.confirmPassword) {
				this.valid=false
				this.password=""
				this.confirmPassword=""
				this.errorMessage='The password and confirmation password do not match'
				this.$forceUpdate();
			}
			else{
				this.valid=true
				this.loading = true
				this.savePassword()
			}
		},
		validatePassword: function(value){
            let specialSigns = "@$!%*?&#";
            let checks = {l: false, u: false, d: false, s: false};
            for(var i = 0; i < value.length; i++){
                if (specialSigns.includes(value[i])){
                    checks.s = true;
                } else if ('0' <= value[i] && value[i] <= '9'){
                    checks.d = true;
                } else if ('a' <= value[i] && value[i] <= 'z'){
                    checks.l = true;
                } else if ('A' <= value[i] && value[i] <= 'Z'){
                    checks.u = true;
                }
            }
            let isOK = (value.length >= 8) && checks.u && (checks.d || checks.s) && checks.l;
            return isOK;
        },
		savePassword(){
			var that = this
			let data = {
				'password': this.password,
				'password2': this.confirmPassword,
				'token': this.$route.params.token
			}
			axios({
                method: 'PATCH',
                url: appConfig.getApiUrl(null) + "/api/v1/reset-password/",
                data: data
            }).then(() => {
				this.loading=false
                this.password = "";
                this.confirmPassword = "";
				this.errorMessage=""
				this.value=true

                this.passwordUpdateNotification = this.lview.updatedSuccessfully;
                this.snackbarColor = 'success';
                this.passwordUpdateNotifier = true;

                setTimeout(function(){
                    that.$router.push("/login");
                }, 5000);
            }).catch(error => {
				this.loading=false
                this.password = "";
                this.confirmPassword = "";
				this.errorMessage=""
				this.value=true

                if (typeof(error.response) === 'undefined'){
				this.globalErrorMessage = "Application failed to connect to the server. Please check your internet connection.";
				return;
			}

			switch(error.response.status){
				case 400:
					console.log(error.response.data)
					this.passwordUpdateNotification = "Invalid request";
					this.passwordUpdateNotifier = true;
					this.snackbarColor = "error";
					break
				case 403:
					this.passwordUpdateNotification = this.lview.linkAlreadyUsed;
					this.passwordUpdateNotifier = true;
					this.snackbarColor = "error";
					break;
				case 404:
					this.passwordUpdateNotification = this.lview.tryAgainChangePasswordLaterMessage;
					this.passwordUpdateNotifier = true;
					this.snackbarColor = "error";
					break;
				case 429:
					this.passwordUpdateNotification = this.lview.tryAgainChangePasswordLaterMessage;
					this.passwordUpdateNotifier = true;
					this.snackbarColor = "error";
					break;
				case 500:
					this.passwordUpdateNotification = "An internal server error has occured. Please report this message to the administrator.";
					this.passwordUpdateNotifier = true;
					this.snackbarColor = "error";
					break;
			}
            });
		},
		isTokenValid: async function(token) {
            return await axios({
                url: appConfig.getApiUrl(null) + "/api/v1/token-verification/",
                method: "POST",
				data: {
					token: token
				}
            });
        }
    }
	};
</script>

<style scoped>

.main{
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 100%;
	padding-top: 0;
}


.wrapper{
	width: 30%;
	margin-left: 35%;
	display: flex;
	justify-content: flex-end;
	align-content: center;
	flex-wrap: wrap;
	justify-content: center;
}

.logo-wrapper{
	width: 80%;
	margin:40px 10%;
}

.form-wrapper{
	flex-basis: 100%;
	text-align: center;
}

.v-btn{
	color: #ffffff;
	width: 180px;
	padding: 2px;
	margin: 20px;
}

.reset-btn:hover{
	box-shadow: 1px 1px 5px #6a7b8b;
}

.warning-wrapper{
	display: flex;
	justify-content: space-between;
	font-size: 12px;
}

a {
	text-decoration: none;
	font-size: 14px;
}

@media only screen and (max-width: 1904px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 1264px) { 
	.wrapper {
		width: 40%;
		margin-left: 30%;
		transform: translate(-120px,0);
	}
}

@media only screen and (max-width: 960px) { 
	.wrapper {
		width: 50%;
		margin-left: 25%;
		transform: translate(0);
	}
}

@media only screen and (max-width: 600px) { 
	.wrapper {
		width: 70%;
		margin-left: 15%;
		transform: translate(0);
	}
}

</style>
